<template>
  <div>
    <h3 align='center'>Restricted</h3>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>